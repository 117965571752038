var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.internalJob.bySlug)?_c('div',{staticClass:"page_section"},[_c('MobileTitle',{attrs:{"title":_vm.internalJob.bySlug.title}}),_c('Banner',{attrs:{"content":{
                    image: '/abellio_banner.jpg',
                    smallBanner: true
                }}}),_c('div',{staticClass:"container view-job-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{staticClass:"mb30",attrs:{"links":[
               {
                  title: _vm.__t('homepage'),
                  path: '/',
                  active: false
               },
               {
                  title: this.internalJob.bySlug.title,
                  path: this.$route.path,
                  active: true
               },
            ]}})],1),(_vm.internalJob.bySlug.image)?_c('div',{staticClass:"col-12"},[_c('FullImage',{staticClass:"mb30",staticStyle:{"height":"25rem"},attrs:{"content":{
                    image: _vm.internalJob.bySlug.image,
                }}})],1):_vm._e(),_c('div',{staticClass:"content-wrapper job-form-builder",class:{'col-md-8': _vm.hasLeftPart, 'col-md-12': !_vm.hasLeftPart}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.internalJob.bySlug.title))]),_c('div',{staticClass:"type-title"},[_vm._v(_vm._s(_vm.internalJob.bySlug.job_type.name))]),(_vm.internalJob.bySlug.location)?_c('div',{staticClass:"subtitle pb20"},[_c('strong',[_vm._v("Standort:")]),_vm._v(" "+_vm._s(_vm.internalJob.bySlug.location)+" ")]):_vm._e(),_vm._l((_vm.internalJob.bySlug.content_elements),function(element,index){return _c('ContentElements',{key:element._group + '-' + index,attrs:{"content":element}})})],2),(_vm.hasLeftPart)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"contact-info"},[_c('div',{staticClass:"inner"},[_c('h4',{staticClass:"title mb20"},[_vm._v("Downloads")]),_vm._l((_vm.documents),function(document,index){return _c('a',{key:index,attrs:{"href":_vm.cmsAsset(document.document),"target":"_blank"}},[_c('i',{staticClass:"ab-icon-arrow-right"}),_vm._v(" "+_vm._s(document.title)+" ")])}),(_vm.internalJob.bySlug.video_link)?_c('iframe',{staticClass:"mt20",attrs:{"width":"100%","height":"240px","src":_vm.internalJob.bySlug.video_link,"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}}):_vm._e()],2)])]):_vm._e()])]),_c('Footer')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }