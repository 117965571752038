<template>
   <div class="page_section" v-if="internalJob.bySlug">
      <MobileTitle :title="internalJob.bySlug.title"/>
       <!-- title: internalJob.bySlug.title,
                    subTitle: internalJob.bySlug.job_type.title, -->
      <Banner :content="{
                    image: '/abellio_banner.jpg',
                    smallBanner: true
                }"/>
      <div class="container view-job-wrapper">
         <div class="row">
            <div class="col-12">
               <Breadcrumb :links="[
               {
                  title: __t('homepage'),
                  path: '/',
                  active: false
               },
               {
                  title: this.internalJob.bySlug.title,
                  path: this.$route.path,
                  active: true
               },
            ]" class="mb30"/>
            </div>

            <div class="col-12" v-if="internalJob.bySlug.image">
               <FullImage :content="{
                    image: internalJob.bySlug.image,
                }"
                 class="mb30"
                 style="height: 25rem;"
               />
            </div>

            <div class="content-wrapper job-form-builder" :class="{'col-md-8': hasLeftPart, 'col-md-12': !hasLeftPart}">
               <h1 class="title">{{ internalJob.bySlug.title }}</h1>
               <div class="type-title">{{ internalJob.bySlug.job_type.name }}</div>
               <div class="subtitle pb20" v-if="internalJob.bySlug.location">
                  <strong>Standort:</strong> {{ internalJob.bySlug.location }}
               </div>
<!--               <div class="content" v-html="internalJob.bySlug.content"></div>-->
               <ContentElements v-for="(element, index) in internalJob.bySlug.content_elements" :content="element" :key="element._group + '-' + index"/>
               <!-- <div class="job-form-builder">
                  <FormBuilder v-if="jobForm" :showTitle="false" :id="jobForm.id"
                        :content="{title: jobForm.title, form_elements: jobForm.questions}"/>
               </div> -->
            </div>
            <div class="col-md-4" v-if="hasLeftPart">
               <div class="contact-info">
                  <div class="inner">
                     <h4 class="title mb20">Downloads</h4>
                     <a v-for="(document, index) in documents"
                        :key="index" :href="cmsAsset(document.document)" target="_blank">
                        <i class="ab-icon-arrow-right"></i>
                        {{ document.title }}
                     </a>

                     <iframe width="100%" height="240px" class="mt20" v-if="internalJob.bySlug.video_link"
                             :src="internalJob.bySlug.video_link" frameborder="0"
                             allow="autoplay; encrypted-media"
                             allowfullscreen=""></iframe>

                  </div>

                  <!--                  <a href="#" class="btn apply-btn">-->
                  <!--                     Jetzt online bewerben-->
                  <!--                  </a>-->
               </div>
            </div>
         </div>

      </div>
      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";
   import Footer from "../_common/Footer";
   import Breadcrumb from "../_common/Breadcrumb";
   import ContentElements from "../_content_elements/ContentElements";
   import FullImage from "../_content_elements/FullImage";

   // import FormBuilder from "../_content_elements/FormBuilder";

   export default {
      name: "Job",
      components: {
         Footer,
         ContentElements,
         // FormBuilder,
         MobileTitle,
         Breadcrumb,
         Banner,
         FullImage
      },
      data() {
         return {
            banner: {
               title: 'Mechatroniker (m/w/d) oder Elektroniker (m/w/d)',
               subTitle: 'STELLENBÖRSE',
               image: '/abellio_banner.jpg',
               smallBanner: true
            },
            infoWinOpen: 0,
            jobForm: null
         }
      },
      computed: {
         ...mapState([
            'internalJob',
         ]),
         hasLeftPart() {
            if (this.internalJob.bySlug) {
               return this.internalJob.bySlug.video_link || (this.internalJob.bySlug.documents && this.documents.length > 0);
            }

            return false;
         },
         documents() {
            let documents = [];

            if (this.internalJob.bySlug && this.internalJob.bySlug.documents && this.internalJob.bySlug.documents.length > 0) {
               this.internalJob.bySlug.documents.forEach(function (item) {
                  if (item.title !== '' && item.document !== '') {
                     documents.push(item);
                  }
               });
            }
            return documents;
         }
      },
      created() {
         this.$store.commit('internalJob/setBySlug', null);
         this.$store.dispatch('internalJob/getBySlug', this.$route.params.slug)
         .then((resp) => {
            this.jobForm = resp.content_elements.find(ele => ele._group === 'form_selector')
         })
      },
      beforeDestroy() {
         this.$store.commit('internalJob/setBySlug', null);
      }
   }
</script>

<style lang="scss" scoped>
   .job_map_wrapper {
      width: 100%;
      height: 350px;
   }
</style>
